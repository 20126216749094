import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { SearchBarService } from './search-bar.service';
import { ArrowRightSvgComponent, SearchSvgComponent } from '@uc/shared/svg';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'uc-search-bar',
	standalone: true,
	templateUrl: './search-bar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [SearchSvgComponent, ArrowRightSvgComponent, CommonModule],
})
export class SearchComponent implements OnDestroy {
	@Input() searchType = '';
	@Input() displayButton = false;
	@Input() placeholder = 'Search';
	@Input() redirectTo = '';
	@Input() keyword = '';
	@Input() buttonStyles =
		'bg-white text-uc-blue-900 border-l-2 border-l-uc-blue-900 hover:bg-gray-100';
	@Input() uniSlug!: string;
	@Input() openSearchInNewTab = false;
	@Input() customStyles = 'py-3 lg:py-5';
	@Input() iconPadding = 'pl-6';

	@ViewChild('searchBox') searchBox!: ElementRef<HTMLInputElement>;

	constructor(
		private _router: Router,
		private _searchSrv: SearchBarService,
	) {}

	// reset the BehaviorSubject to initial value
	ngOnDestroy(): void {
		this._searchSrv.onValueChange('', '', false, true);
	}

	onSearch(keyword: string) {
		if (this.redirectTo) {
			if (!this.openSearchInNewTab) {
				this._redirectSameTab(keyword);
			} else {
				this._navigateThroughNewTab(keyword);
			}

			this.searchBox.nativeElement.value = '';
		}
	}

	onChange(event: any) {
		if (typeof event === 'string') {
			this._searchSrv.onValueChange(event, this.searchType);
			return;
		}
		this._searchSrv.onValueChange(event.target.value, this.searchType);
	}

	private _redirectSameTab(keyword: string) {
		if (!keyword.trim()) {
			this._router.navigate([this.redirectTo], {
				queryParams: { uni: this.uniSlug },
			});
		} else {
			this._router.navigate([this.redirectTo], {
				queryParams: { s: keyword.toLowerCase().trim(), uni: this.uniSlug },
			});
		}
	}

	private _navigateThroughNewTab(keyword: string) {
		const queryparams = [];
		if (this.uniSlug) {
			queryparams.push(`uni=${this.uniSlug}`);
		}

		if (keyword.trim()) {
			queryparams.push(`s=${keyword.toLowerCase().trim()}`);
		}

		if (queryparams.length === 0) {
			window.open(this.redirectTo, '_blank');
		} else {
			window.open(`${this.redirectTo}?${queryparams.join('&')}`, '_blank');
		}
	}
}
